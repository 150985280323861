<template>
  <header class="era-header">
    <div class="era-header-container flex-row align-center">
      <div class="flex-1" style="height:100%">
        <!-- <img src="@/assets/images/logo-2.svg" height="100%" style="cursor: pointer;padding: 0.8vw;" onclick="javascript: location.href='/home'"/> -->
        <div class="header-logo" style="height:100%;line-height: 0.47rem;">
          <img class="cur-point" style="width:1.6rem;" src="@/assets/images/logo-2.svg"
            @click="onLink('Home', '/home', '#home')" />
        </div>
      </div>
      <div v-for="(link, index) in links" class="era-header-link flex-row align-center" :key="index"
        @click="onLink(link.name, link.path, link.hash)">
        <div class="era-header-link-border"></div>
        <div class="era-header-link-text" style="padding: 0vw 3.75vw 0vw 0.46vw;">{{ link.text }}</div>
      </div>
    </div>
  </header>
  <!-- <v-app-bar app color="white" elevation="12" class="header">
    <v-img
          class="cur-point"
          width="7.2vw"
          src="@/assets/images/logo-header.png"
          @click="onLink('Home', '/home', '#home')"
        >
        </v-img>
    <v-tabs centered class="ml-n9" color="grey darken-1">
      <v-tab
        v-for="link in links"
        :key="link"
        @click="onLink(link.name, link.path, link.hash)"
        class="era-header-link"
      >
        <div class="era-header-link-border"></div>
        <div class="era-header-link-text">{{ link.text }}</div>
      </v-tab>
    </v-tabs>
  </v-app-bar> -->
</template>

<script>
export default {
  name: "PageHeader",
  data: () => ({
    /**
     * 头部选项
     */
    links: [
      {
        text: "关于我们",
        name: "Home",
        path: "/home",
        hash: "#aboutUs",
      },
      {
        text: "技术",
        name: "Home",
        path: "/home",
        hash: "#technology",
      },
      {
        text: "产品",
        name: "Home",
        path: "/home",
        hash: "#product",
      },

      {
        text: "新闻",
        name: "News",
        path: "/news",
        hash: "",
      },
      {
        text: "加入我们",
        name: "Home",
        path: "/home",
        hash: "#joinUs",
      },
    ],
  }),
  methods: {
    /**
     * 点击头部选项
     */
    onLink(name, path, hash) {
      if (this.$route.path == path) {
        if (hash) {
          switch (hash) {
            case '#aboutUs':
              this.$vuetify.goTo('#aboutUs', { offset: -250 });
              break;
            case '#technology':
              this.$vuetify.goTo('#technology', { offset: -100 });
              break;
            case '#product':
              this.$vuetify.goTo('#catalytic', { offset: 100 });
              break;
            case '#joinUs':
              this.$vuetify.goTo('#joinUs', { offset: 150 });
              break;
            default:
          }
          // console.log(hash, 'hash');
          // this.$vuetify.goTo(hash, { offset: 156 });
        } else {
          window.location.reload();
        }
      } else {
        if (name) {
          if (hash) {
            this.$router.push({
              name: name,
              params: {
                hash: hash,
              },
            });
          } else {
            this.$router.push({
              name: name,
            });
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.era-header {
  position: fixed;
  width: 100vw;
  padding: 0 1%;
  top: 0;
  z-index: 200;
  background-color: #ffffff;
  // background-color: #615bfd;
  box-shadow: 0px 0.03rem 0.06rem 0.01rem rgba(0, 0, 0, 0.16);

  .era-header-container {
    height: 3.9vw;

    .header-logo {
      //width: 8.4vw;
      // width: 160px;
    }

    .era-header-link {
      cursor: pointer;

      // height:  3.8vw;
      .era-header-link-border {
        transition: height 0.5s;
        //width: 5px;
        width: 0.26vw;
        height: 0;
        background: #615bfd;
      }

      .era-header-link-text {
        font-size: 1vw;
        font-weight: 500;
        color: #000000;
      }
    }

    .era-header-link:hover {
      .era-header-link-border {
        height: 1.5vw;
      }
    }
  }
}

.header {
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16) !important;
  z-index: 200 !important;

  .era-header-link {
    .era-header-link-text {
      color: #000000 !important;
    }
  }
}
</style>
